import { useTheme } from 'native-base';

import { Ellipse51Icon, EllipsePartialIcon, EllipseCompletedIcon } from '../icons';

type ProgressStatusProps = {
  isWeekly: boolean;
  total?: number;
  value?: number;
};

export const ProgressStatus = ({ total = 0, value = 0, isWeekly = false }: ProgressStatusProps) => {
  const { colors } = useTheme();

  if (value > 0 && total > 0) {
    if (value === total) {
      return <EllipseCompletedIcon size={5} color={colors.blueLight[500]} />;
    }
    return <EllipsePartialIcon size={5} color={colors.warning[400]} />;
  }
  return <Ellipse51Icon size={5} color={colors.gray[400]} />;
};
