import React from 'react';
import { ProgressCircle } from 'react-native-svg-charts';
import { Box, HStack, useTheme } from 'native-base';

import { Text } from '@pimm/base';

type ProgressCircleCardProps = {
  _container?: React.ComponentProps<typeof Box>;
  title: string;
  total?: number;
  value?: number;
};

export const ProgressCircleCard = ({ _container, title, total = 0, value = 0 }: ProgressCircleCardProps) => {
  const { colors } = useTheme();
  const color = value === total ? colors.blueLight[500] : colors.warning[400];
  const percentage = (value / total) * 100 || 0;

  return (
    <Box {..._container}>
      <Box rounded="xl" borderWidth={1} bg="gray.25" bgColor="white">
        <HStack alignItems="center" justifyContent="space-between" py={3} px={4} borderBottomWidth={1}>
          <Text size="lg" fontWeight={700} color="gray.900" textTransform="uppercase" lineHeight="xs">
            {title}
          </Text>
        </HStack>

        <HStack space={3} alignItems="center" justifyContent="center" pt={3} minH={120}>
          <Box position="relative" alignItems="center" height="full" w="180px" maxH="80px" overflow="hidden">
            <Text size="4xl" color="gray.900" fontWeight={600} position="absolute" zIndex={1} bottom={3}>
              {`${Math.round(percentage)}%`}
            </Text>
            <ProgressCircle
              style={{ width: 190, height: 130 }}
              backgroundColor={colors.gray[100]}
              progress={percentage / 100}
              progressColor={color}
              startAngle={-Math.PI * 0.5}
              endAngle={Math.PI * 0.5}
              strokeWidth={10}
              cornerRadius={6}
            />
          </Box>

          <Text size="2xl" fontWeight={600} color="gray.700">
            <Text size="3xl" fontWeight={700} color="gray.700">
              <Text size="3xl" fontWeight={700} color="black">
                {value}
              </Text>
              /{total}
            </Text>{' '}
            Completed
          </Text>
        </HStack>
      </Box>
    </Box>
  );
};
