const translations = {
  auto_assign: 'Auto Assign',
  auto_assign_all: 'Auto Assign All',
  calendar: 'Calendar',
  cancel: 'Cancel',
  cleaning_task_schedule: 'Cleaning Task Schedule',
  coke_freestyle: 'Coke Freestyle',
  completed: 'Completed',
  daily_positional_goals: 'Daily Positioning Goals',
  edit_goals: 'Edit Goals',
  equipment_events: 'Equipment Events',
  goals: 'Weekly Goals',
  last_update: 'Last Update',
  name: 'Name',
  no_assigned_tasks: 'No assigned tasks',
  no_available_employees: 'No available employees',
  no_data_found: 'No data found',
  no_employee_assigned: 'No Employee Assigned',
  no_events_today: 'No events today',
  no_goals_today: 'No goals today',
  non_service_plan: 'Non-Service Plan',
  phase: 'Phase',
  position: 'Position',
  positions: 'Positions',
  position_plan: 'Position Plan',
  position_responsibilities: 'Position Responsibilities',
  primary_position: 'Primary Position',
  secondary_position_s: 'Secondary Position(s)',
  service_positions: 'Service Positions',
  shift_start: 'Shift Start',
  shift_end: 'Shift End',
  start_time: 'Start Time',
  temperatures: 'Temperatures',
  unassigned: 'Unassigned',
  widget_center: 'Widget Center',
  speed_of_service: 'Speed of Service',
};

export default translations;
