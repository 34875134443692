import { Box, HStack, useTheme } from 'native-base';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLabel } from 'victory';

import { Text } from '@pimm/base';

type ProgressBarCardProps = {
  _container?: React.ComponentProps<typeof Box>;
  title: string;
  weeklySummary?: {
    day?: string;
    completion?: number;
  }[];
};

export const ProgressBarCard = ({ _container, title, weeklySummary = [] }: ProgressBarCardProps) => {
  const { colors } = useTheme();

  return (
    <Box {..._container}>
      <Box rounded="xl" borderWidth={1} bg="gray.25" bgColor="white">
        <HStack alignItems="center" justifyContent="space-between" py={3} px={4} borderBottomWidth={1}>
          <Text size="lg" fontWeight={700} color="gray.900" textTransform="uppercase" lineHeight="xs">
            {title}
          </Text>
        </HStack>

        <VictoryChart
          height={280}
          domainPadding={{ x: [50, 50] }}
          padding={{ top: 40, left: 60, bottom: 40, right: 60 }}
          theme={{
            chart: {},
            axis: {
              style: {
                grid: { stroke: 0 },
                axisLabel: {
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: 600,
                  fontSize: 12,
                },
                tickLabels: {
                  paddingTop: 0,
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: 500,
                  fontSize: 12,
                },
              },
            },
          }}
        >
          <VictoryAxis
            tickValues={weeklySummary.map(d => d.day)}
            tickLabelComponent={<VictoryLabel dy={2} />}
            style={{
              axis: { stroke: colors.gray[100] },
              tickLabels: { padding: 2 },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickValues={[0, 20, 40, 60, 80, 100]}
            tickLabelComponent={<VictoryLabel dx={-5} />}
            label="Completion (%)"
            labelComponent={<VictoryLabel style={{ fontSize: 'md', color: colors.gray[600], padding: 10 }} />}
            style={{
              axis: { stroke: 'transparent' },
              grid: { stroke: colors.gray[100] },
              tickLabels: { padding: 2 },
            }}
            domain={[0, 100]}
          />
          <VictoryGroup>
            <VictoryBar
              data={weeklySummary}
              x="day"
              y="completion"
              barWidth={15}
              cornerRadius={{ top: 5 }}
              style={{
                data: {
                  fill: ({ datum }) =>
                    datum.completion === 0 ? 'transparent' : datum.completion === 100 ? colors.blueLight[500] : colors.warning[400],
                },
              }}
            />
          </VictoryGroup>
        </VictoryChart>
      </Box>
    </Box>
  );
};
