import { Fragment, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Box, CircleIcon, CloseIcon, Divider, HStack, Pressable, ScrollView, VStack, useMediaQuery } from 'native-base';
import { find, map, size } from 'lodash';
import moment from 'moment';

import { Button, CalendarCheckIcon, Modal, Text } from '@pimm/base';
import { PositionGroupLiveDto } from '@pimm/services/lib/sms-workforce';
import { formatTo, stringToDateLocal } from '@app/utils/date-formatter';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useGetClientId } from '@app/features/app/hooks';
import { SignalRContext, SignalRData, SignalREffectKey } from '@app/features/signalr/context';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import {
  GetPositionScheduleLiveParams,
  PopoverTooltipShiftCheckPoints,
  PositioningAvailableEmployees,
  PositioningConfirmUnSave,
  PositioningDropdownOptions,
  PositioningPlanConfirmation,
  PositioningPlanConsumer,
  PositioningPlanNonServices,
  PositioningPlanProvider,
  PositioningPlanServices,
  PositioningSubBlocks,
  ShiftBlock,
  useClickToDrop,
  useGetPositionScheduleLive,
  useShiftBlockFocus,
} from '@app/features/positioning-plan';
import { ResourceLoader } from '@app/components/shared';
import { ModalEmployeeSchedules } from './modal-employee-schedules';
import { ModalStaffReview } from './modal-staff-review';

type ServicePlanProps = {
  dayBlock: DayBlock;
  editable?: boolean;
  siteId: string;
  onChangeBlockNumber: (blockNumber: number) => void;
};

export const ServicePlan = ({ dayBlock, siteId, ...props }: ServicePlanProps) => {
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1024 });
  const queryClient = useQueryClient();
  const xClientId = useGetClientId();
  const siteTime = useSiteTime();
  const modalSchedules = useModalFocus();
  const modalUnSave = useModalFocus<{ confirm: () => void }>();
  const shiftBlockFocus = useShiftBlockFocus();
  const [liveSchedules, setLiveScheduleParams] = useGetPositionScheduleLive();
  const { dropItem, setDragItem } = useClickToDrop();

  const isDropEnabled = !!dropItem;

  const { dayBlocks, isDisabled, isReadOnly } = useMemo(() => {
    const timeNow = siteTime.today();
    const dayBlocks = siteTime.toDayBlocks(dayBlock.startTime);
    const isReadOnly = dayBlock.endTime < timeNow;

    let isDisabled = !dayBlock || dayBlock.endTime < timeNow;

    // Check if shiftSubBlock is available, disable past shift subBlock
    if (!isDisabled && shiftBlockFocus.selected && shiftBlockFocus.all.length) {
      const shiftStartTime = stringToDateLocal(shiftBlockFocus.selected);
      if (shiftStartTime) {
        const shiftBlock = find<ShiftBlock>(shiftBlockFocus.all, shift => {
          return !!shift.startTime && !!shift.endTime && shiftStartTime >= shift.startTime && shiftStartTime < shift.endTime;
        });

        if (shiftBlock?.startTime && shiftBlock?.endTime) {
          isDisabled = shiftBlock.endTime < timeNow;
        }
      }
    }
    return {
      dayBlocks: dayBlocks,
      isDisabled: isDisabled,
      isReadOnly: isReadOnly,
    };
  }, [dayBlock, shiftBlockFocus.all, shiftBlockFocus.selected]);

  SignalRContext.useSignalREffect(
    SignalREffectKey,
    jsonString => {
      const queryCache = queryClient.getQueryCache();
      const message: SignalRData<PositionGroupLiveDto, { siteId: string; blockStartDateTime: string; subBlockDateTime?: string }> =
        JSON.parse(jsonString);
      if (!xClientId || message.data.clientId === xClientId) return;

      if (message.moduleName === 'PositionSchedule') {
        const _payload = message.data.payload;
        const _data = message.data.data;

        const cachedGetPositionScheduleLive = queryCache.getAll().find(cache => {
          const params = cache.queryKey[1] as GetPositionScheduleLiveParams;
          return (
            cache.queryKey.includes('GetPositionScheduleLive') &&
            params.siteId === _payload.siteId &&
            moment(params.dayBlock.startTime).format().slice(0, 19) === _payload.blockStartDateTime?.slice(0, 19)
            // Note: skip checking subBlockTime for now, disabling this condition will allow the ui to
            // shiftBlockFocus.selected?.slice(0, 19) === _payload.SubBlockDateTime?.slice(0, 19)
          );
        });

        if (cachedGetPositionScheduleLive && _data) {
          // Note: do not delete, for reference only
          // queryClient.refetchQueries({ queryKey: cachedGetPositionScheduleLive.queryKey });
          queryClient.setQueryData<PositionGroupLiveDto>(cachedGetPositionScheduleLive?.queryKey, (_prevData): PositionGroupLiveDto => {
            const _subBlockTime = _data.positionGroup?.subBlockTime ?? _payload.subBlockDateTime?.slice(0, 19);

            // If subBlockTime exist, need to compare if we need to return the whole data or the shiftSubBlocks only
            if (_payload.subBlockDateTime && shiftBlockFocus.selected?.slice(0, 19) !== _payload.subBlockDateTime.slice(0, 19)) {
              return { ..._prevData, shiftSubBlocks: _data.shiftSubBlocks };
            }
            return {
              ..._data,
              positionGroup: {
                ..._data.positionGroup,
                // Making sure to keep the subBlockDateTime
                subBlockTime: _subBlockTime,
              },
            };
          });
        }
      }
    },
    [],
  );

  const handleChangeShiftBlock = (shiftBlock: ShiftBlock) => {
    shiftBlockFocus.changeFocus(shiftBlock.id);
    if (shiftBlock.startTime) {
      setLiveScheduleParams(prev => ({
        ...prev!,
        subBlockStartTime: shiftBlock.startTime,
      }));
    }
  };

  const handleFullDayReset = () => {
    const allBlocks = siteTime.toDayBlocks(dayBlock.startTime);
    const dayBlockNow = siteTime.toDayBlockNow();
    const activeBlock =
      find(allBlocks, _ => {
        return _.startTime >= dayBlockNow?.startTime && dayBlockNow.startTime < _.endTime;
      }) ?? allBlocks[0];
    if (!activeBlock.blockNumber) return;
    if (activeBlock.blockNumber === dayBlock?.blockNumber) {
      liveSchedules.refetch();
    } else {
      props.onChangeBlockNumber(activeBlock.blockNumber);
    }
  };

  const handleUnSaveChanges = (callback: (p: any) => void, unsave?: boolean) => (p: any) => {
    if (!unsave) callback(p);
    else {
      modalUnSave.setOpen({
        confirm: () => {
          modalUnSave.setHide();
          callback(p);
        },
      });
    }
  };

  useEffect(() => {
    // Highlight pre-selected shiftSubBlock startTime
    if (liveSchedules.status === 'success') {
      if (liveSchedules.data) {
        const { positionGroup, shiftSubBlocks } = liveSchedules.data;
        shiftBlockFocus.reset(shiftSubBlocks, positionGroup?.subBlockTime);
      }
    }
  }, [liveSchedules.status, liveSchedules.data]);

  useEffect(() => {
    if (dayBlock?.startTime && siteId) {
      setLiveScheduleParams({ dayBlock: dayBlock, siteId: siteId });
      // Reset subBlockTime on change siteId or dayBlockFocus
      shiftBlockFocus.reset();
    }
  }, [dayBlock, siteId]);

  return (
    <VStack h="full">
      {!!dayBlock && (
        <Fragment>
          <PositioningPlanProvider dayBlock={dayBlock} liveSchedules={liveSchedules}>
            <PositioningPlanConsumer>
              {({ changes, positioning }) => (
                <Fragment>
                  {/* DayBlocks */}
                  <HStack space={2} alignItems="center" py={2} px={3} borderBottomWidth={1}>
                    <HStack flex={1} space={{ md: 1, xl: 2 }} alignItems="center">
                      {map(dayBlocks, block => {
                        const timeNow = siteTime.today();
                        const isActive = timeNow >= block.startTime && timeNow <= block.endTime;
                        const isSelected = block.blockNumber === dayBlock?.blockNumber;
                        return (
                          <Pressable
                            key={block.blockNumber}
                            flex={1}
                            rounded="lg"
                            maxW={{ md: 190, xl: 200 }}
                            opacity={!isDropEnabled ? 1 : 0.2}
                            isDisabled={isDropEnabled}
                            onPress={handleUnSaveChanges(() => props.onChangeBlockNumber(block.blockNumber!), changes.length > 0)}
                          >
                            {({ isHovered }) => (
                              <HStack
                                space={{ md: 1, xl: 2 }}
                                rounded="lg"
                                alignItems="center"
                                px={{ md: 1, lg: 2, xl: 3 }}
                                h="44px"
                                borderWidth={1}
                                borderColor={isSelected ? 'gray.800' : isHovered ? 'gray.500' : 'gray.200'}
                                bg={isSelected ? 'gray.800' : isHovered ? 'gray.200' : 'gray.50'}
                              >
                                {isActive && <CircleIcon size="8px" color="blueLight.500" />}
                                <Box flex={1}>
                                  <Text
                                    size={{ md: 'sm', xl: 'md' }}
                                    fontWeight={700}
                                    color={isSelected ? 'white' : 'gray.800'}
                                    lineHeight="xs"
                                    numberOfLines={1}
                                    textTransform="uppercase"
                                  >
                                    {block.title}
                                  </Text>
                                  <Text
                                    size={{ md: 'xs', xl: 'sm' }}
                                    fontWeight={500}
                                    color={isSelected ? 'white' : 'gray.800'}
                                    lineHeight="xs"
                                    numberOfLines={1}
                                    textTransform="uppercase"
                                  >
                                    {block.subTitle}
                                  </Text>
                                </Box>
                                <Box rounded="md" justifyContent="center" px={1} h={5} minW={{ xl: '60px' }} bg="white">
                                  <Text size={{ md: 'xs', xl: 'sm' }} fontWeight={700} color="black" lineHeight="xs" textAlign="center">
                                    {formatTo(block.startTime, isSmallScreen ? 'h A' : 'h:mm A')}
                                  </Text>
                                </Box>
                              </HStack>
                            )}
                          </Pressable>
                        );
                      })}
                    </HStack>

                    {!isDropEnabled && (
                      <ModalStaffReview
                        dayBlock={dayBlock}
                        siteId={siteId}
                        onNavigate={modalSchedules.setOpen}
                        onReset={handleFullDayReset}
                        trigger={triggerProps => (
                          <Button
                            accessibilityLabel="Schedule Overview"
                            variant="unstyled"
                            startIcon={<CalendarCheckIcon size={5} />}
                            {...triggerProps}
                            onPress={handleUnSaveChanges(triggerProps.onPress, changes.length > 0)}
                          >
                            Schedule Overview
                          </Button>
                        )}
                      />
                    )}

                    {!isReadOnly &&
                      !isDropEnabled &&
                      liveSchedules.status === 'success' &&
                      changes.length === 0 &&
                      size(liveSchedules.data?.shiftSubBlocks) < 2 && (
                        <PositioningDropdownOptions
                          dayBlock={dayBlock}
                          isDisabled={isDisabled}
                          liveSchedules={liveSchedules}
                          siteId={siteId}
                        />
                      )}
                  </HStack>

                  {/* Shift SubBlocks */}
                  <VStack flex={1}>
                    {shiftBlockFocus.all.length > 1 && (
                      <>
                        <HStack space={2} alignItems="center" justifyContent="space-between" px={3} h="50px" borderBottomWidth={1}>
                          <HStack space={2} alignItems="center" opacity={!isDropEnabled ? 1 : 0.3}>
                            <PositioningSubBlocks
                              isDisabled={isDropEnabled}
                              shiftBlockFocus={shiftBlockFocus}
                              onChange={handleUnSaveChanges(handleChangeShiftBlock, changes.length > 0)}
                            />
                            {!isDropEnabled && <PopoverTooltipShiftCheckPoints />}
                          </HStack>

                          {!isReadOnly && !isDropEnabled && (
                            <PositioningDropdownOptions
                              dayBlock={dayBlock}
                              isDisabled={isDisabled || liveSchedules.status !== 'success'}
                              liveSchedules={liveSchedules}
                              siteId={siteId}
                            />
                          )}

                          {isDropEnabled && (
                            <Button
                              minHeight={9}
                              minWidth={70}
                              bg="black"
                              borderColor="black"
                              _hover={{ bg: 'black' }}
                              _pressed={{ bg: 'black' }}
                              _text={{ color: 'white' }}
                              shadow={1}
                              startIcon={<CloseIcon size={3} color="white" style={{ mr: 2 }} />}
                              onPress={() => setDragItem(undefined)}
                            >
                              Exit
                            </Button>
                          )}
                        </HStack>
                      </>
                    )}

                    <Box flex={1}>
                      <ResourceLoader h="full" isLoading={liveSchedules.status === 'idle' || liveSchedules.status === 'loading'}>
                        <HStack h="full">
                          <VStack w={280} borderRightWidth={1}>
                            <PositioningPlanConfirmation
                              _container={{ opacity: isDropEnabled ? 0.3 : 1 }}
                              isDisabled={isDropEnabled}
                              onOpenSchedules={modalSchedules.setOpen}
                            />

                            <Box flex={1}>
                              <PositioningAvailableEmployees isDisabled={isDisabled} />
                            </Box>
                          </VStack>

                          <Box flex={1} bg="gray.50">
                            <ScrollView contentContainerStyle={{ flexGrow: 1 }} h="full">
                              <HStack minHeight="full">
                                <Box flex={1}>
                                  <PositioningPlanServices
                                    emptyMessage={
                                      !isReadOnly && (
                                        <VStack space={2} alignItems="center" justifyContent="center" h="full" w="full">
                                          <Text fontWeight={500} size="md" lineHeight="md" color="gray.700">
                                            No Service Positions have been assigned
                                          </Text>

                                          <PositioningDropdownOptions
                                            _container={{ minWidth: '140px' }}
                                            dayBlock={dayBlock}
                                            isDisabled={isDisabled || liveSchedules.status !== 'success'}
                                            liveSchedules={liveSchedules}
                                            siteId={siteId}
                                            hideOptions
                                          />
                                        </VStack>
                                      )
                                    }
                                    isDisabled={isDisabled}
                                  />
                                </Box>

                                <Divider position="sticky" top={0} orientation="vertical" height="full" w="1px" bg="gray.300" />

                                <Box display={{ base: 'none', xl: 'flex' }} w={360}>
                                  <PositioningPlanNonServices isDisabled={isDisabled} />
                                </Box>
                              </HStack>
                            </ScrollView>
                          </Box>
                        </HStack>
                      </ResourceLoader>
                    </Box>
                  </VStack>

                  {/* TODO: Fix dayBlock type issue */}
                  <ModalEmployeeSchedules dayBlock={dayBlock as any} isOpen={modalSchedules.isOpen} onClose={modalSchedules.setHide} />
                </Fragment>
              )}
            </PositioningPlanConsumer>
          </PositioningPlanProvider>
        </Fragment>
      )}

      <Modal
        size="md"
        _content={{ rounded: 'xl', p: 5, pt: 4, maxW: 400 }}
        isOpen={modalUnSave.isOpen}
        noPadding
        hideClose
        onClose={modalUnSave.setHide}
      >
        <PositioningConfirmUnSave onConfirm={modalUnSave.payload?.confirm} onClose={modalUnSave.setHide} />
      </Modal>
    </VStack>
  );
};
