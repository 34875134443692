import { useState } from 'react';
import { Box, CircleIcon, HStack } from 'native-base';
import { Text } from '@pimm/base';
import { EmpLearningPathStatusEnum } from '@pimm/services/lib/sms-workforce';

type BadgeStatusProps = {
  status: EmpLearningPathStatusEnum;
};

export const LearningPathBadge = ({ status }: BadgeStatusProps) => {
  const [{ color, displayText }] = useState<{ color: string; displayText: string }>(
    {
      [EmpLearningPathStatusEnum.Pending]: { color: 'gray', displayText: 'Pending' },
      [EmpLearningPathStatusEnum.Enrolled]: { color: 'gray', displayText: 'Enrolled' },
      [EmpLearningPathStatusEnum.Started]: { color: 'warning', displayText: 'Started' },
      [EmpLearningPathStatusEnum.Complete]: { color: 'blueLight', displayText: 'Completed' },
    }[status] ?? { color: 'gray', displayText: 'Pending' },
  );

  return (
    <Box
      rounded="lg"
      flex="none"
      alignItems="center"
      justifyContent="center"
      px={1}
      minWidth="60px"
      h="18px"
      borderWidth={1}
      borderColor={`${color}.400`}
      bg={`${color}.100`}
    >
      <HStack space={1} alignItems="center">
        <CircleIcon size={2} color={`${color}.400`} />
        <Text size="sm" fontWeight={400} color={`${color}.700`} lineHeight="xs">
          {displayText}
        </Text>
      </HStack>
    </Box>
  );
};
