import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Box, HStack, VStack, View, Pressable, Spacer, ScrollView, SectionList, IconButton, CloseIcon, useTheme } from 'native-base';
import { useTranslation } from 'react-i18next';
import { find, findIndex, isEmpty, map } from 'lodash';

import { Button, Text, SearchField, ZapIcon } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AddUpdateOpsTaskAssignmentRequest, EmployeeDto } from '@pimm/services/lib/sms-workforce';
import { AddUpdateOpsTaskAssignment } from '@pimm/services/lib/sms-workforce/services';
import { formatToISOString, formatToShortTimeOnly, formatToTimeOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { formatName } from '@app/utils/string-formatter';
import { ResourceLoader } from '@app/components/shared';
import { useSiteTime } from '@app/features/store-core';
import { useEmployeesByRoles } from '@app/features/store-info/hooks';
import { useOpsPhasePositioning } from '../context';
import { GetOpsTasksFlowChartAggLiveParams, useMutateAutoAssignOpsPositioning, useUpdateOpsTasksFlowChart } from '../hooks';
import { OpsPhasePositionAssignee } from '../types';
import { OpsPhaseAssignment, OpsPhasePositionAssignment } from '../reducers/ops-phase-positioning.reducer';

type EligableEmployees = Record<string, ReturnType<typeof useEmployeesByRoles>[0]['data']>;

export type SelectEligableEmployeeProps = {
  assignee?: OpsPhasePositionAssignee;
  opsPhaseId?: number;
  positionId: string;
  salesVolumeProfileId: number;
  siteId?: string;
  startOfDay?: Date;
  onChange?: (assignee?: OpsPhasePositionAssignee, isDelete?: boolean, positionId?: string) => void;
  onClose?: () => void;
};

export const SelectEligableEmployee = ({ salesVolumeProfileId, siteId, startOfDay, ...props }: SelectEligableEmployeeProps) => {
  const { colors } = useTheme();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const updateOpsTasksFlowChart = useUpdateOpsTasksFlowChart();
  const { positioning, updatePosition } = useOpsPhasePositioning();

  const sectionListRef = useRef<any>(null);
  const [searchValue, setSearchValue] = useState<string>();
  const [employeesByPhase, setEmployeesByPhase] = useState<EligableEmployees>({});
  const [opsTaskPhase, setOpsTaskPhase] = useState<
    { opsPhaseId: number; positionId: string; assignee?: OpsPhasePositionAssignee } | undefined
  >(props.positionId && props.opsPhaseId ? { positionId: props.positionId, opsPhaseId: props.opsPhaseId } : undefined);

  const autoAssignOpsPositioning = useMutateAutoAssignOpsPositioning();
  const addUpdateTaskAssignment = useMutation({ mutationFn: AddUpdateOpsTaskAssignment });

  const eligableEmployees = useEmployeesByRoles(opsTaskPhase?.opsPhaseId ? employeesByPhase[opsTaskPhase?.opsPhaseId] : undefined, {
    searchValue: searchValue,
  });

  const opsPhaseFocus = find(positioning.opsPhases, ['id', opsTaskPhase?.opsPhaseId]) ?? positioning.opsPhases[0];
  const positionFocus = find(opsPhaseFocus?.positions, ['id', opsTaskPhase?.positionId]) ?? opsPhaseFocus?.positions?.[0];

  const sections = useMemo(() => {
    return map(positioning.opsPhases, ({ positions, ...phase }: OpsPhaseAssignment) => ({
      ...phase,
      data: positions,
    }));
  }, [positioning?.opsPhases]);

  const handleAutoAssign = async () => {
    if (siteId && startOfDay) {
      await autoAssignOpsPositioning.mutateAsync({ date: startOfDay, siteId: siteId });
    }
  };

  const handlePressPosition = (opsPhaseId?: number, position?: OpsPhasePositionAssignment) => () => {
    if (!!opsPhaseId && !!position?.id) {
      setOpsTaskPhase({
        opsPhaseId: opsPhaseId,
        positionId: position?.id,
      });
    }
  };

  const handlePressSelect = (employee: EmployeeDto) => () => {
    if (opsTaskPhase && !autoAssignOpsPositioning.isLoading && !addUpdateTaskAssignment.isLoading) {
      const assignee: OpsPhasePositionAssignee = {
        id: positionFocus.assignee?.id ?? 0,
        employeeId: employee.id!,
        name: formatName(employee.firstName, employee.lastName),
      };

      setOpsTaskPhase({ ...opsTaskPhase, assignee: assignee });
    }
  };

  const handlePressSave = async () => {
    if (!!opsTaskPhase?.opsPhaseId && !!opsTaskPhase?.assignee && startOfDay && siteId) {
      const startEndOfDay = siteTime.toStartEndOfBlock();
      const queryCache = queryClient.getQueryCache();

      const payload: AddUpdateOpsTaskAssignmentRequest = {
        id: opsTaskPhase?.assignee?.id,
        siteId: siteId,
        employeeId: opsTaskPhase?.assignee.employeeId,
        assignDate: formatToISOString(startOfDay, true),
        opsPhaseId: opsTaskPhase?.opsPhaseId,
        salesVolumeProfileId: salesVolumeProfileId,
        positionId: opsTaskPhase?.positionId,
      };

      const result = await addUpdateTaskAssignment.mutateAsync(payload);

      if (result.id) {
        const opsTaskAssignmentId = result.id;

        updatePosition(opsPhaseFocus.id, {
          assignee: { ...opsTaskPhase?.assignee, id: opsTaskAssignmentId },
          id: opsTaskPhase.positionId,
        });

        // Try to update live flowchart
        const cachedGetOpsTasksFlowChartAggLive = queryCache.getAll().find(cache => {
          if (cache.queryKey[0] === 'GetOpsTasksFlowChartAggLive') {
            const { opsPhaseId, siteId: _siteId } = cache.queryKey[1] as GetOpsTasksFlowChartAggLiveParams;
            return siteId === _siteId && (!opsPhaseId || opsPhaseId === opsTaskPhase.opsPhaseId);
          }
          return false;
        });

        if (cachedGetOpsTasksFlowChartAggLive?.queryKey[1]) {
          const { startTime } = positioning.opsPhases.find(_ => _.id === opsTaskPhase.opsPhaseId) ?? {};

          if (
            startTime &&
            startEndOfDay.startTime &&
            startEndOfDay.endTime &&
            startTime >= startEndOfDay.startTime &&
            startTime < startEndOfDay.endTime
          ) {
            updateOpsTasksFlowChart(cachedGetOpsTasksFlowChartAggLive.queryKey[1], 'AssignmentChanged', {
              id: opsTaskAssignmentId,
              employeeId: opsTaskPhase?.assignee?.employeeId,
              positionId: opsTaskPhase?.positionId,
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    const eligableEmployees = positioning?.opsPhases.reduce((_eligableEmployees: EligableEmployees, _opsPhase) => {
      if (isEmpty(_opsPhase.eligableEmployees)) return _eligableEmployees;
      return {
        ..._eligableEmployees,
        [_opsPhase.id]: _opsPhase.eligableEmployees!.map(_ => ({
          ..._.employee,
          startTime: _.startTime,
          endTime: _.endTime,
        })),
      };
    }, {});
    setEmployeesByPhase(eligableEmployees);
  }, [positioning?.opsPhases]);

  useEffect(() => {
    if (!opsTaskPhase?.assignee) return;

    // When an employee is selected, wait 500ms and auto-trigger handlePressSave.
    // This replaces the old flow where the user had to manually click the Save button.
    const timeout = setTimeout(() => {
      handlePressSave();
    }, 500);

    return () => clearTimeout(timeout);
  }, [opsTaskPhase?.assignee?.employeeId]);

  useEffect(() => {
    if (sectionListRef?.current) {
      const opsPhases = positioning.opsPhases;
      const opsPhaseIndex = findIndex(opsPhases, _ => _.id === opsTaskPhase?.opsPhaseId) ?? 0;
      const positionIndex = findIndex(opsPhases?.[opsPhaseIndex]?.positions, _ => _.id === opsTaskPhase?.positionId) ?? 0;
      // Add delay to make sure that the sectionlist are rendered first before doing the scroll
      setTimeout(() => {
        sectionListRef.current.scrollToLocation({
          sectionIndex: opsPhaseIndex,
          itemIndex: positionIndex,
        });
      }, 700);
    }
  }, []);

  return (
    <View w="full" h="full">
      <HStack alignItems="center" px={4} h="56px" borderBottomWidth={1} justifyContent="space-between">
        <Text size="2xl" fontWeight={700} color="gray.900">
          Select Employee
        </Text>

        {!!props.onClose && (
          <IconButton
            rounded="lg"
            variant="unstyled"
            boxSize={8}
            p={0}
            _pressed={{ bg: 'gray.50' }}
            icon={<CloseIcon size="18px" color={colors.gray[700]} />}
            onPress={props.onClose}
          />
        )}
      </HStack>

      <HStack flex={1}>
        <VStack w="1/3" borderRightWidth={1} space={4} pt={4}>
          <Button
            accessibilityLabel="Reset and Auto Assign Employee"
            isLoading={autoAssignOpsPositioning.isLoading}
            onPress={handleAutoAssign}
            startIcon={<ZapIcon size="18px" color="black" />}
            mx={4}
          >
            {t('common:auto_assign_all')}
          </Button>
          <SectionList
            ref={sectionListRef}
            maxW={420}
            flex={1}
            px={4}
            extraData={[opsTaskPhase]}
            removeClippedSubviews
            scrollEnabled
            sections={sections}
            stickySectionHeadersEnabled={false}
            onScrollToIndexFailed={() => ({
              index: 0,
            })}
            renderSectionHeader={({ section }) => {
              return (
                <HStack
                  alignItems="center"
                  px={3}
                  h={10}
                  bg="gray.200"
                  borderTopRadius="md"
                  borderTopLeftRadius="md"
                  space={2}
                  justifyContent="space-between"
                >
                  <Text size="lg" fontWeight={700} color="black" lineHeight="xs" textAlign="center">
                    {translate(section?.phase, section?.translations)}
                  </Text>
                  {!!section?.startTime && !!section.endTime && (
                    <Box flex="none" rounded="md" justifyContent="center" px={1} h={5} borderWidth={1} borderColor="gray.300" bg="white">
                      <Text size="sm" color="gray.700" fontWeight={600} lineHeight="sm" textAlign="center">
                        {`${formatToShortTimeOnly(section.startTime)} - ${formatToShortTimeOnly(section.endTime)}`}
                      </Text>
                    </Box>
                  )}
                </HStack>
              );
            }}
            renderItem={({ item: _position, index, section }) => {
              const isLastItem = index === section.data.length - 1;
              const isActive = _position.id === opsTaskPhase?.positionId && section.id === opsTaskPhase?.opsPhaseId;

              return (
                <VStack
                  bg="white"
                  borderWidth={1}
                  borderTopWidth={0}
                  mb={isLastItem ? 4 : 0}
                  borderBottomRadius={isLastItem ? 'lg' : 'none'}
                >
                  <Pressable key={_position?.id} onPress={handlePressPosition(section?.id, _position)}>
                    {({ isFocused, isHovered }) => (
                      <HStack
                        space={1}
                        alignItems="center"
                        justifyContent="space-between"
                        px={3}
                        minH={60}
                        borderBottomRadius={isLastItem ? 'lg' : 'none'}
                        bg={isFocused || isHovered || isActive ? 'primary.50' : 'white'}
                      >
                        <VStack space={1}>
                          <Text size="md" fontWeight={700} color="gray.800" lineHeight="xs" textTransform="uppercase">
                            {translate(_position.title, _position.translations)}
                          </Text>

                          <Text size="md" color={!!_position?.assignee ? 'gray.900' : 'gray.500'} lineHeight="xs">
                            {!!_position?.assignee ? _position.assignee?.name : 'No Assigned Employee'}
                          </Text>
                        </VStack>

                        {!!_position.startTime && (
                          <Text size="md" color="gray.700" fontWeight={600} lineHeight="sm" textAlign="center">
                            {`${formatToShortTimeOnly(_position.startTime)}`}
                          </Text>
                        )}
                      </HStack>
                    )}
                  </Pressable>
                </VStack>
              );
            }}
          />
        </VStack>

        {/* Right Pane */}
        <VStack flex={1}>
          <HStack space={4} height={60} px={4} py={2} alignItems="center" justifyContent="space-between" borderBottomWidth={1}>
            <Box>
              <Text size="md" fontWeight={500} color="gray.700" lineHeight="md" textTransform="uppercase">
                {opsPhaseFocus?.phase}
              </Text>

              <HStack space={2} alignItems="center">
                <Text fontWeight={700} size="lg" color="black" lineHeight="md">
                  {positionFocus.title}
                </Text>
                <Text size="md" fontWeight={500} color="black" lineHeight="md" textTransform="uppercase">
                  {`(${formatToShortTimeOnly(positionFocus?.startTime)} - ${formatToShortTimeOnly(positionFocus?.endTime)})`}
                </Text>
              </HStack>
            </Box>

            <Box flex={1} maxW="320px">
              <SearchField
                placeholder="Search Employee"
                value={searchValue}
                onChangeText={setSearchValue}
                onClear={() => setSearchValue(undefined)}
              />
            </Box>
          </HStack>

          <Box flex={1} p={4} borderBottomRightRadius="xl" bg="gray.25">
            {/* Employee List */}
            <VStack
              flex={1}
              space={4}
              position="relative"
              h="full"
              rounded="xl"
              overflow="hidden"
              w="full"
              borderColor="gray.200"
              borderWidth={1}
              bg="gray.50"
            >
              <ResourceLoader h="full" w="full" emptyMessage="No available employees" isEmpty={isEmpty(eligableEmployees)}>
                <ScrollView py={3} h="full">
                  <VStack space={2}>
                    {map(eligableEmployees, section => {
                      return (
                        <Box key={section.title} px={3}>
                          <Box mb={1} px={1}>
                            <Text color="gray.900" fontWeight={700} size="lg" lineHeight="sm">
                              {section.title}
                            </Text>
                          </Box>

                          <HStack flexWrap="wrap">
                            {map(section.data, _employee => {
                              const isActive = _employee.id === (opsTaskPhase?.assignee?.employeeId ?? positionFocus?.assignee?.employeeId);
                              const startTime = stringToDateLocal(_employee.startTime);
                              const endTime = stringToDateLocal(_employee.endTime);

                              return (
                                <Box key={`${opsTaskPhase?.opsPhaseId}.${_employee.id}`} flex="none" p={1} w="1/4">
                                  <Pressable rounded="lg" onPress={handlePressSelect(_employee!)}>
                                    {({ isHovered }) => (
                                      <Box
                                        position="relative"
                                        rounded="lg"
                                        alignItems="center"
                                        justifyContent="center"
                                        px={2}
                                        h="64px"
                                        borderWidth={1}
                                        borderColor={isActive ? 'primary.200' : isHovered ? 'gray.200' : 'white'}
                                        bg={isActive ? 'primary.50' : 'white'}
                                      >
                                        <Text
                                          color={isActive ? 'primary.700' : isHovered ? 'black' : 'gray.700'}
                                          fontWeight={600}
                                          size="md"
                                          lineHeight="sm"
                                          textAlign="center"
                                          numberOfLines={2}
                                          ellipsizeMode="tail"
                                        >
                                          {formatName(_employee.firstName, _employee.lastName)}
                                        </Text>

                                        <Text
                                          color={isActive ? 'primary.700' : isHovered ? 'black' : 'gray.600'}
                                          fontWeight={400}
                                          size="sm"
                                          textAlign="center"
                                          numberOfLines={1}
                                        >
                                          {`${formatToTimeOnly(startTime)} - ${formatToTimeOnly(endTime)}`}
                                        </Text>
                                      </Box>
                                    )}
                                  </Pressable>
                                </Box>
                              );
                            })}
                          </HStack>
                        </Box>
                      );
                    })}
                  </VStack>
                </ScrollView>
              </ResourceLoader>

              <Box flex={1}>
                <VStack rounded="xl" overflow="hidden" h="full" w="full" borderColor="gray.200" borderWidth={1}>
                  {/* List of employees */}
                  <Box flex={1} bg="gray.50"></Box>
                </VStack>
              </Box>
            </VStack>
          </Box>
        </VStack>
      </HStack>
    </View>
  );
};
