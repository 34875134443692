import { useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { ArrowBackIcon, ArrowForwardIcon, Box, HStack, Pressable, ScrollView, Spacer, TextArea, useTheme, VStack } from 'native-base';
import { find, findIndex, isEmpty, map, size } from 'lodash';

import { Button, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { WeeklyFocusCategoryDto } from '@pimm/services/lib/sms-positioning';
import { PreviousWeeklyFocusDto } from '@pimm/services/lib/sms-workforce';
import { formatToStartEndOfWeek, stringToDateLocal } from '@app/utils/date-formatter';
import { CompletionIcon, ResourceLoader } from '@app/components/shared';
import { useSiteTime } from '@app/features/store-core';
import { useFormWeeklyFocus } from '../hooks';
import { Copy01Icon } from '../icons';

type FormWeeklyCategoriesProps = {
  categories?: WeeklyFocusCategoryDto[];
  defaultCategoryId?: number;
  isEditable?: boolean;
  form: ReturnType<typeof useFormWeeklyFocus>;
  previousWeeklyFocus?: PreviousWeeklyFocusDto[];
  week?: ReturnType<ReturnType<typeof useSiteTime>['toStartEndOfWeekBlock']>;
};

export const FormWeeklyCategories = ({
  categories,
  defaultCategoryId,
  form,
  isEditable,
  previousWeeklyFocus,
  week,
}: FormWeeklyCategoriesProps) => {
  const { colors } = useTheme();
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const [categoryId, setCategoryId] = useState<number | undefined>(defaultCategoryId ?? categories?.[0]?.id);
  const formValues = useWatch({ control: form.control, name: 'categories' });

  const pageMax = size(categories) - 1;
  const pageIndex = findIndex(categories, ['id', categoryId]);
  const category = categories?.[pageIndex];

  const handleChangeText = (_description?: string) => () => {
    form.setValue(`categories.${categoryId}.description`, _description ?? '', { shouldDirty: true });
  };

  const handlePaginate = (num: number) => () => {
    const category = categories?.[pageIndex + num];
    if (category) setCategoryId(category.id);
  };

  return (
    <HStack flex={1} h="full">
      <VStack w="1/3" borderRightWidth={1} bg="white">
        <Box pt={4} pb={1} px={4}>
          {!!week && (
            <Text size="2xl" fontWeight={700} color="black">
              {formatToStartEndOfWeek(week.weekNumber, week.startTime)}
            </Text>
          )}
        </Box>

        <ScrollView _contentContainerStyle={{ paddingY: 2, paddingX: 4 }}>
          <VStack space={3}>
            {map(categories, _category => {
              return (
                <Pressable
                  key={_category.id}
                  isFocused={!!categoryId && _category.id === categoryId}
                  onPress={() => setCategoryId(_category.id)}
                >
                  {({ isFocused, isHovered }) => (
                    <HStack
                      space={3}
                      rounded="lg"
                      alignItems="center"
                      px={2}
                      minH={10}
                      borderWidth={2}
                      borderColor={isFocused || isHovered ? 'primary.500' : 'gray.200'}
                      bg="white"
                    >
                      <HStack flex={1} space={2} alignItems="center">
                        <Box justifyContent="center" h={6} w={6} bg="gray.100" rounded="md">
                          <Text size="md" fontWeight={700} color="black" lineHeight="xs" textAlign="center">
                            {_category.seq}
                          </Text>
                        </Box>
                        <Text size={{ xl: 'xl', md: 'md' }} fontWeight={700} color="black" lineHeight="xs" textTransform="uppercase">
                          {translate(_category.title, _category.translations)}
                        </Text>
                      </HStack>

                      {!!_category.id && (
                        <CompletionIcon
                          variant={isEmpty(formValues?.[_category.id.toString()]?.description) ? 'blank' : 'complete'}
                          size={5}
                        />
                      )}
                    </HStack>
                  )}
                </Pressable>
              );
            })}
          </VStack>
        </ScrollView>
      </VStack>

      {/* Right Content */}
      <VStack bg="gray.50" flex={1} p={4} pt={0}>
        <HStack alignItems="center" minH="55px" w="full">
          {!!category && (
            <HStack alignItems="center" space={2}>
              {!!category?.id && (
                <CompletionIcon variant={isEmpty(formValues?.[category.id.toString()]?.description) ? 'blank' : 'complete'} size={5} />
              )}

              <Box justifyContent="center" boxSize={6} bg="gray.400" rounded="md">
                <Text size="lh" fontWeight={700} color="black" lineHeight="xs" textAlign="center">
                  {category.seq}
                </Text>
              </Box>

              <Text size="3xl" fontWeight={700} color="black" lineHeight="xs" textTransform="uppercase">
                {translate(category.title, category.translations)}
              </Text>
            </HStack>
          )}

          <Spacer />

          <HStack space={2} justifyContent="center">
            <Button variant="unstyled" disabled={pageIndex === 0} w={10} onPress={handlePaginate(-1)}>
              <ArrowBackIcon color="gray.700" size={5} />
            </Button>

            <Button variant="unstyled" disabled={pageIndex === pageMax} w={10} onPress={handlePaginate(1)}>
              <ArrowForwardIcon color="gray.700" size={5} />
            </Button>
          </HStack>
        </HStack>

        <HStack rounded="xl" borderWidth={1} bg="white" flex={1}>
          <VStack space={3} w="1/2" borderRightWidth={1} p={4} py={3}>
            <HStack alignItems="center" justifyContent="space-between" minH={isEditable ? 10 : 7}>
              {!!week && (
                <Text size="xl" fontWeight={700} color="black">
                  {formatToStartEndOfWeek(week.weekNumber, week.startTime)}
                </Text>
              )}

              {!!isEditable && (
                <Button
                  variant="unstyled"
                  minW={90}
                  disabled={!categoryId || isEmpty(formValues?.[categoryId.toString()]?.description)}
                  onPress={handleChangeText()}
                >
                  Clear
                </Button>
              )}
            </HStack>

            <Controller
              key={`categories.${categoryId}`}
              name={`categories.${categoryId}.description`}
              control={form.control}
              render={({ field: { value, onBlur, onChange } }) => (
                <TextArea
                  _stack={{ py: 2 }}
                  autoCapitalize="sentences"
                  autoCompleteType={false}
                  autoFocus
                  isDisabled={!isEditable}
                  flex={1}
                  borderRadius="md"
                  fontFamily="body"
                  fontSize="lg"
                  placeholder="Type goal..."
                  value={value ?? ''}
                  onBlur={onBlur}
                  onChangeText={text => onChange({ target: { value: text ?? '' } })}
                />
              )}
            />

            <HStack space={2} justifyContent="center">
              <Button variant="unstyled" disabled={pageIndex === 0} w={10} onPress={handlePaginate(-1)}>
                <ArrowBackIcon color="gray.700" size={5} />
              </Button>

              <Button variant="unstyled" disabled={pageIndex === pageMax} w={10} onPress={handlePaginate(1)}>
                <ArrowForwardIcon color="gray.700" size={5} />
              </Button>
            </HStack>
          </VStack>

          {/* Previous Weeks  */}
          <VStack space={3} flex={1} p={4} py={3}>
            <VStack justifyContent="center" minH={isEditable ? 10 : 7}>
              <Text size="xl" fontWeight={700} color="black">
                Goal History
              </Text>
              {!!isEditable && (
                <HStack space={2}>
                  <Text size="md" fontWeight={500} color="gray.700" lineHeight="sm">
                    Can be copied to this week by using the copy button
                  </Text>
                  <Copy01Icon color={colors.gray[700]} />
                </HStack>
              )}
            </VStack>

            {/* Previous Week Data */}
            <ResourceLoader h="full" w="full" emptyMessage="No available data" isEmpty={isEmpty(previousWeeklyFocus)}>
              <VStack space={3} overflowY="auto" flex={1}>
                {map(previousWeeklyFocus, _previousWeek => {
                  if (!_previousWeek.weekStartDate) return null;

                  const startOfWeek = stringToDateLocal(_previousWeek.weekStartDate);
                  const _week = siteTime.toStartEndOfWeek(startOfWeek);
                  const _description = find(_previousWeek.focusItems, ['weeklyFocusCategoryId', categoryId])?.description;

                  return (
                    <VStack borderRadius="lg" borderWidth={1} bg="gray.25" px={3} key={`previous-weekly-[${_previousWeek.id}]`}>
                      <HStack alignItems="center" py={1.5} minH={10}>
                        <Text fontWeight={700} color={isEmpty(_description) ? 'gray.500' : 'black'} size="lg">
                          {formatToStartEndOfWeek(_week.weekNumber, _week.startDate)}
                        </Text>

                        <Spacer />

                        {!!isEditable && !isEmpty(_description) && (
                          <Button variant="unstyled" size="sm" w="30px" minH={7} bg="white" onPress={handleChangeText(_description)}>
                            <Copy01Icon color={colors.gray[700]} />
                          </Button>
                        )}
                      </HStack>

                      {!isEmpty(_description) && (
                        <Box pt={1} pb={3}>
                          <Text fontWeight={500} lineHeight="sm" size="md" color="gray.900" ellipsizeMode="tail" numberOfLines={5}>
                            {_description}
                          </Text>
                        </Box>
                      )}
                    </VStack>
                  );
                })}
              </VStack>
            </ResourceLoader>
          </VStack>
        </HStack>
      </VStack>
    </HStack>
  );
};
