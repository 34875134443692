import { Icon, IIconProps } from 'native-base';
import { Circle } from 'react-native-svg';

export const EllipseCompletedIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 23 23" fill="none" {...props}>
      <Circle cx="11.6786" cy="11.7916" r="10" stroke="blueLight.500" fill="none" strokeWidth="2" />
      <Circle cx="11.6786" cy="11.7916" r="7.5" fill="blueLight.500" />
    </Icon>
  );
};
