import { Pressable } from 'react-native';
import { Box, CloseIcon, HStack, IBoxProps, Icon, IconButton, ScrollView, VStack, useTheme } from 'native-base';
import { map, padStart, range } from 'lodash';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import moment from 'moment';

import { Text, Modal } from '@pimm/base';
import { CalendarEvent } from '../context/calendar-events-context';
import { BadgeCalendarEvent } from './badge-calendar-event';
import { useModalFocus } from '@app/hooks/modal-focus.hook';

type DailyEventsProps = IBoxProps & {
  date: Date;
  events: CalendarEvent[];
  hideDay?: boolean;
  isSameMonth?: boolean;
};

export const DailyEvents = ({ date, events, hideDay = false, isSameMonth, ...props }: DailyEventsProps) => {
  const { colors } = useTheme();
  const modalEvents = useModalFocus();

  return (
    <Box flexDirection={{ md: 'column', xl: 'row' }} px={{ md: 1.5, xl: 2 }} {...props}>
      {!hideDay && (
        <Box pt={3} pr={3}>
          <Text size="xl" color={isSameMonth ? 'black' : 'gray.500'}>
            {padStart(`${date.getDate()}`, 2, '0')}
          </Text>
        </Box>
      )}

      <VStack pt={{ md: hideDay ? 3 : 1, xl: 3 }} flex={1} space={1}>
        {map(hideDay ? events : events.slice(0, 1), (event, index) => (
          <BadgeCalendarEvent key={`${date}.${index}`} w="full" rounded="sm" date={date} event={event} />
        ))}

        {!hideDay && events.length > 2 && (
          <Pressable onPress={modalEvents.setOpen}>
            <HStack space={1.5} alignItems="center" justifyContent="space-between" pl={1} minHeight="22px" bgColor="gray.50" rounded="sm">
              <Text size="md" flex={1} color="gray.900" lineHeight="md">
                +{events.length - 1} more
              </Text>
              <Icon as={MaterialIcons} name="chevron-right" size="md" color="gray.900" />
            </HStack>
          </Pressable>
        )}

        <Modal
          size="xl"
          _content={{ rounded: 'xl', maxW: '30%', minH: 300 }}
          isOpen={modalEvents.isOpen}
          noPadding
          hideClose
          onClose={modalEvents.setHide}
        >
          <HStack
            alignItems="center"
            justifyContent="space-between"
            pl={4}
            pr={2}
            h="50px"
            borderBottomWidth={1}
            borderBottomColor="gray.100"
          >
            <Text size="lg" fontWeight={600} color="gray.900">
              Events ({moment(date).format('MMM DD, YYYY')})
            </Text>

            <IconButton
              rounded="lg"
              variant="unstyled"
              boxSize={8}
              _pressed={{ bg: 'gray.50' }}
              icon={<CloseIcon size="18px" color={colors.gray[700]} />}
              onPress={modalEvents.setHide}
            />
          </HStack>

          <ScrollView maxHeight={460}>
            <VStack space={2} p={4}>
              {events.map((event, index) => (
                <BadgeCalendarEvent
                  key={`${date.toISOString()}-modal-${index}`}
                  w="full"
                  rounded="sm"
                  date={date}
                  event={event}
                  minHeight="32px"
                />
              ))}
            </VStack>
          </ScrollView>
        </Modal>
      </VStack>
    </Box>
  );
};
